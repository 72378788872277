export default {
  siteTitle: 'Get bonus',
  blogerName: 'SLOTRBET',
  socialsList: [],
  projects: [
    {
      name: 'jet',
      url: 'https://jetcasino.life/cc8f7620a',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c2d4cd833',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/ca6b82056',
      gameTitle: 'All Lucky Clover 5 (BGaming)',
    }
  ],
  headerText: 'Promosyonu kullan <strong>LUCK999</strong> aşağıdaki slotlarda 50FS almak için!',
  yourPromocode: 'Promosyon kodunuz',
  gameBonus: '',
  gameBonusAfter: 'slotunda bonus',
  promocode: 'LUCK999',
  casinoBonus1: '<strong>100%</strong><div>İlk depozito için</div>',
  casinoBonus2: '<strong>50FS</strong><div>Promosyon koduyla kaydolmak için</div>',
  firstDeposit: 'For the first deposit',
  copyMessage: 'Promosyon kodu kopyalandı',
  theme: 'dark'
};
